import React, { useEffect, useRef, useState } from 'react';
import EmissionTrendGraph from './EmissionTrendGraph';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import useAuth from '../../../../hooks/useAuth';
// import { scope12Emissions, scope12IntensityEmissions, scope12IntensityReductions, scope12Reductions, financialSavings, financialSavingsProd, scope123Emissions, scope123Reductions, financialSavingsScope123, financialSavingsScope123Intensity, financialSavingsScope123Reductions, scope123Intensity, scope123IntensityReductions } from './testData';

const EmissionTrends = props => {
    const [showScope3, setShowScope3] = useState(false);

    var init = useRef(true);
    const [isLoading, setIsLoading] = useState(true);

    const [scope12Emissions, setScope12Emissions] = useState([]);
    const [scope12Reductions, setScope12Reductions] = useState([]);
    const [scope12IntensityEmissions, setScope12IntensityEmissions] = useState([]);
    const [scope12IntensityReductions, setScope12IntensityReductions] = useState([]);
    const [financialSavings, setFinancialSavings] = useState([]);
    const [financialSavingsNormalized, setFinancialSavingsNormalized] = useState([]);

    const [siteNames, setSiteNames] = useState([]);
    const [selectableSites, setSelectableSites] = useState([]);
    const [siteLookup, setSiteLookup] = useState({});

    const axiosProtect = useAxiosProtect();
    const {auth, authContext} = useAuth();

    const [errorMessage, setErrorMessage] = useState('');
    const [actualPct, setActualPct] = useState(0);

    const updatePct = dataSet => {
        let currentYear = new Date().getFullYear();
        let counter = 0;

        for(let i = 0;i<dataSet.length;++i){
            if(dataSet[i].year <= currentYear) ++counter;
            else{
                setActualPct(100 * counter / dataSet.length);
                return;
            }
        }

        setActualPct(100);
    }

    useEffect(() => {
        if(!init.current) return;


        axiosProtect.get(`/auth/query/list-client-site-access?uid=${auth?.id}`).then(response => {
            let siteNameArr = [];
            let siteLookupObj = {};
            console.log(response.data.sites);
            for(let i = 0;i<response.data.sites.length;++i){
                siteNameArr.push(response.data.sites[i].name)
                siteLookupObj[response.data.sites[i].name] = response.data.sites[i].id
            }

            setSiteNames(siteNameArr);
            setSelectableSites(siteNameArr);
            setSiteLookup(siteLookupObj);

            axiosProtect.get(`/tg/targets/emissions-graphs?sites=${Object.values(siteLookupObj).join(',')}`).then(response => {
                setScope12Emissions(response.data.graphData.scope12Emissions);
                setScope12Reductions(response.data.graphData.scope12Reductions);
                setScope12IntensityEmissions(response.data.graphData.scope12EmissionsNormalized);
                setScope12IntensityReductions(response.data.graphData.scope12ReductionsNormalized)
                setFinancialSavings(response.data.graphData.financialSavings);
                setFinancialSavingsNormalized(response.data.graphData.financialSavingsNormalized);

                updatePct(response.data.graphData.scope12Emissions);

                setIsLoading(false);
                init.current = false;

            }).catch(err => {
                setIsLoading(false); 
                setErrorMessage(err.response.data);
                init.current = false;
            });
        }).catch(err => {setErrorMessage(err.response.data);});
    }, []);

    useEffect(() => {
        if(!isLoading || init.current) return;

        let siteIDs = [];

        for(let i = 0;i<siteNames.length;++i){
            siteIDs.push(siteLookup[siteNames[i]]);
        }

        axiosProtect.get(`/tg/targets/emissions-graphs?sites=${siteIDs.join(',')}`).then(response => {
            setScope12Emissions(response.data.graphData.scope12Emissions);
            setScope12Reductions(response.data.graphData.scope12Reductions);
            setScope12IntensityEmissions(response.data.graphData.scope12EmissionsNormalized);
            setScope12IntensityReductions(response.data.graphData.scope12ReductionsNormalized)
            setFinancialSavings(response.data.graphData.financialSavings);
            setFinancialSavingsNormalized(response.data.graphData.financialSavingsNormalized);

            updatePct(response.data.graphData.scope12Emissions);

            setIsLoading(false);
            setErrorMessage('');
            init.current = false;

        }).catch(err => {
            setScope12Emissions([]);
            setScope12Reductions([]);
            setScope12IntensityEmissions([]);
            setScope12IntensityReductions([])
            setFinancialSavings([]);
            setFinancialSavingsNormalized([]);

            setIsLoading(false); 
            init.current = false;
            setErrorMessage(err.response.data);
        });
    }, [isLoading]);

    const handleSiteSelection = e => {
        const {target: {value}} = e;

        // if(value.length === 0) return;
        setIsLoading(true);

        setSiteNames(typeof value === 'string' ? value.split(',') : value);
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    return(
        <>
            <div style={{width:'100%'}}>
                {/* <FormGroup style={{paddingLeft:'0.5%'}}>
                    <FormControlLabel style={{width:'fit-content'}} control={
                            <Switch style={{color:'rgb(8,114,182)'}} checked={showScope3} onClick={() => setShowScope3(!showScope3)}/>
                        } label={<p style={{margin:'0', fontSize:'95%'}}>Include Scope 3 emissions</p>} />
                </FormGroup> */}

                <div style={{display:'flex', flexDirection:'row'}}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Sites</InputLabel>
                        <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={siteNames}
                        onChange={handleSiteSelection}
                        input={<OutlinedInput label="Sites" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        >
                        {selectableSites.map(site => (
                            <MenuItem key={site} value={site}>
                                <Checkbox checked={siteNames.includes(site)} />
                                <ListItemText primary={site} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                    <div style={{flex:'5'}}></div>

                    {errorMessage !== '' && <Alert severity="error" variant='filled' sx={{ alignItems: 'center', flex:'2' }}>{errorMessage}</Alert>}

                    <div style={{flex:'1'}}></div>
                </div>

                <div style={{display:'flex', flexDirection: 'row', padding:'0.25% 0.5%'}}>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        {
                            isLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{margin:'auto', marginTop:'25%'}}/>
                            </Box>
                            :
                            <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2 & 3' : '1 & 2'} GHG Emissions`} subTitle='' 
                                data={scope12Emissions} pct={actualPct} isFinancial={false} yAxisLabel='tonnes CO2eq/year' showScope3={showScope3}/>
                        }
                    </div>

                    <div style={{flex:'1'}}></div>


                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        {
                            isLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{margin:'auto', marginTop:'25%'}}/>
                            </Box>
                            :
                            <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2 & 3' : '1 & 2'} GHG Intensity`} subTitle='(Normalized by production)' 
                                data={scope12IntensityEmissions} pct={actualPct} isFinancial={false} yAxisLabel='tonnes CO2eq/tonne product' showScope3={showScope3}/>
                        }
                    </div>

                    <div style={{flex:'1'}}></div>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        {
                            isLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{margin:'auto', marginTop:'25%'}}/>
                            </Box>
                            :
                            <EmissionTrendGraph title={`Financial Savings (Scope ${showScope3 ? '1, 2 & 3' : '1 & 2'} Projects)`} subTitle='' 
                                data={financialSavings} pct={actualPct} isFinancial={true} yAxisLabel='$/year' showScope3={showScope3}/>
                        }
                    </div>

                </div>

                <div style={{display:'flex', flexDirection: 'row', padding:'0.25% 0.5%'}}>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        {
                            isLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{margin:'auto', marginTop:'25%'}}/>
                            </Box>
                            :
                            <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2 & 3' : '1 & 2'} GHG Emission Reductions`} subTitle='' 
                                data={scope12Reductions} pct={actualPct} isFinancial={false} yAxisLabel='tonnes CO2eq/year' showScope3={showScope3}/>
                        }
                    </div>

                    <div style={{flex:'1'}}></div>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        {
                            isLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{margin:'auto', marginTop:'25%'}}/>
                            </Box>
                            :
                            <EmissionTrendGraph title={`Scope ${showScope3 ? '1, 2 & 3' : '1 & 2'} GHG Intensity Reductions`} subTitle='(Normalized by production)' 
                                data={scope12IntensityReductions} pct={actualPct} isFinancial={false} yAxisLabel='tonnes CO2eq/tonne product' showScope3={showScope3}/>
                        }
                    </div>

                    <div style={{flex:'1'}}></div>

                    <div style={{flex:'50', height:'40vh', backgroundColor:'white', borderRadius:'1%', boxShadow: '2px 6px 12px rgb(164, 164, 164)', padding:'0.5%'}}>
                        {
                            isLoading ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress style={{margin:'auto', marginTop:'25%'}}/>
                            </Box>
                            :
                            <EmissionTrendGraph title={`Financial Savings (Scope ${showScope3 ? '1, 2 & 3' : '1 & 2'} Projects)`} subTitle='(Normalized by production)' 
                                data={financialSavingsNormalized} pct={actualPct} isFinancial={true} yAxisLabel='$/tonne product' showScope3={showScope3}/>
                        }
                    </div>
                </div>
            </div>

            {/* <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{flex:'50', height:'42vh', backgroundColor:'white', padding:'1vh 1vw', borderRadius:'1%', boxShadow:"2px 6px 12px rgb(164, 164, 164)"}}>
                    <h4>Carbon Reduction Progress</h4>
                    <TargetEmissions parameter='Water' value={1827 * 3} units='m3' graphColor='rgb(8,114,182)' graphId='waterPv' pctChange={4} events={0}graphColor2='rgb(213,43,0)'/>
                </div>

                <div style={{flex:'1'}}></div>

                <div style={{flex:'40', height:'42vh', backgroundColor:'white', padding:'1vh 1vw', borderRadius:'1%', boxShadow:"2px 6px 12px rgb(164, 164, 164)"}}>
                    <h4>Emissions by Component</h4>
                    <UtilityEmissions/>
                </div>
            </div>

            <Divider style={{margin:'1.75vh 0 1.75vh 0'}}/>

            <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                <div style={{height:'42vh', width:'49.25%'}}>
                    <OppEmissions/>
                </div>

                <div style={{width:'1.5%'}}></div>

                <div style={{height:'42vh', width:'49.25%'}}>
                    <OppSavings/>
                </div>
            </div> */}
        </>
    );
}

export default EmissionTrends;
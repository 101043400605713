import React, { useEffect, useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TimeseriesGraph from './TimeseriesGraph';
import useAxiosProtect from '../../hooks/useAxiosProtect';

const HMartContainer = props => {
    const [containerHeight, setContainerHeight] = useState('95vh');

    const [tabValue, setTabValue] = useState('1h');
    const [errorMessage, setErrorMessage] = useState('');
    const [data, setData] = useState([]);

    const axiosProtect = useAxiosProtect();

    var init = useRef(true);

    useEffect(() => {
        const updateContainerHeight = () => {
            let navbar = document.querySelector('.navbar');
            let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];
  
            let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
            let windowHeight = window.innerHeight;
            let remainingHeight = windowHeight - navbarHeight;
  
            setContainerHeight(`${remainingHeight}px`);
        };
      
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
            window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    const fetchData = () => {
        axiosProtect.get(`/hm/hmart?timeframe=${tabValue}`).then(response => {
            setErrorMessage('');
            setData(response.data.data);

            // console.log(response.data.data)

        }).catch(error => {
            setErrorMessage('An error occurred');
        })
    }

    useEffect(() => {
        // if(!init.current) return;
        // init.current = false;

        fetchData();
    }, [tabValue]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    return (
        <div style={{padding:`1% 2%`, backgroundColor:'#f5f5f5', height: containerHeight, overflowX:'hidden'}}>
            <div style={{height:'100%', width:'100%', display:'flex', flexDirection:'column'}}>
                <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary.Mui-selected': {
                                color: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary': {
                                color: 'rgb(8,114,182)'
                            },
                        }}
                        aria-label="secondary tabs example"
                        variant="fullWidth"
                    >
                        <Tab value="1h" label="1 hour" />
                        <Tab value="1d" label="1 day" />
                        <Tab value="1w" label="1 week" />
                        <Tab value="1m" label="1 month" />
                        <Tab value="3m" label="3 months" />
                        <Tab value="6m" label="6 months" />
                    </Tabs>
                </Box>

                {/* <div style={{height:'0%'}}></div> */}

                <div style={{flex:'1', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'10px'}}>
                    <TimeseriesGraph errorMessage={errorMessage} data={data} timeframe={tabValue}/>
                </div>
            </div>
        </div>
    );
}

export default HMartContainer;
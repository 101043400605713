import React, { useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Pagination from '@mui/material/Pagination';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import useAuth from '../../../hooks/useAuth';

const FeedbackPage = props => {
    const [entries, setEntries] = useState([]);

    const [page, setPage] = useState(1);
    const [entryCount, setEntryCount] = useState(0);
    const handleChange = (event, value) => {
        setPage(value);
    };

    const axiosProtect = useAxiosProtect();
    const {auth} = useAuth();

    var init = useRef(true);

    const getEntries = () => {
        axiosProtect.get(`/mgmt/customer-mgmt?page=${page}`).then(response => {
            setEntries(response.data.entries);
            setEntryCount(Math.ceil((parseInt(response.data.totalCount )+ 1) / 20))
        }).catch(err => {

        });
    }

    useEffect(() => {
        getEntries();

    }, [page]);

    const formatDate = unixSeconds => {
        const date = new Date(unixSeconds * 1000);

        return date.toLocaleString("default", {
            hour: "2-digit",
            minute: "2-digit",
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour12: false
        });
    }

    const toggleResolutionStatus = index => {
        let entry = entries[index];

        axiosProtect.put(`/mgmt/customer-mgmt/toggle-resolve?uid=${auth.id}&entry_id=${entry.id}`).then(response => {
            let entryCopy = [...entries];

            for(let i = 0;i<entryCopy.length;++i){
                if(entryCopy[i].id == entry.id){
                    entryCopy[i].resolved = !entryCopy[i].resolved
                    break;
                }
            }

            setEntries(entryCopy);
        }).catch(err => {

        });
    }

    const getCategoryName = category => {
        if(category === 'bug') return 'Bug';
        if(category === 'feature') return 'Feature request';
        if(category === 'comment') return 'Comment';
        return 'Miscellaneous'
    }

    return(
        <div style={{textAlign:'center'}}>
            <Pagination sx={{margin:'auto', width:'fit-content'}} count={entryCount} page={page} onChange={handleChange} />

            <br/>

            <List sx={{ width: '85%', bgcolor: 'background.paper', margin:'auto' }}>
                {
                    entries.map((entry, i) => (
                        <>
                            <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                {
                                    entry.resolved ? 
                                    <Avatar sx={{ bgcolor: '#54B4D3' }}>
                                        <TaskAltIcon />
                                    </Avatar>
                                    :
                                    <Avatar sx={{ bgcolor: '#DC4C64' }}>
                                        <ErrorIcon />
                                    </Avatar>
                                }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<><span>{`${entry.client_name} (${entry.org_name})`}</span> - <span style={{color:'rgb(8,114,182)', fontWeight:'bold'}}>{getCategoryName(entry.category)}</span></>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                            >
                                                {formatDate(entry.date_created)}
                                            </Typography>
                                            <>
                                                {" — "} {entry.message}
                                            </>
                                            <br/>
                                            <br/>
                                            <div>
                                                <FormControlLabel control={<Checkbox checked={entry.resolved} onClick={() => {
                                                    toggleResolutionStatus(i);
                                                }} />} label={`Mark as ${entry.resolved ? 'un' : ''}resolved`} />
                                            </div>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            {i + 1 < entries.length && <Divider component="li" />}
                        </>
                    ))
                }
            </List>
        </div>
    );
}

export default FeedbackPage;
import React from 'react';
import {Line, Tooltip, CartesianGrid, XAxis,YAxis, ResponsiveContainer, Bar, ComposedChart, Cell} from 'recharts';
import WarningIcon from '@mui/icons-material/Warning';
import Skeleton from '@mui/material/Skeleton';
import colors from '../../example_data/colors';

const TimeseriesGraph = props => {
    const formatXAxisLabel = (time) => {
        const date = new Date(time * 1000);

        const hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2,'0');

        let formattedTime = `${hours}:${minutes}`;

        if(!props.timeframe.endsWith('h')){
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            formattedTime = `${year}/${month}/${day} ${formattedTime}`;
        }

        return formattedTime;
    }

    const formatReadings = (reading, name) => {
        return [(Math.round(reading * 100) / 100).toFixed(2), 'pH']
    }

    const CustomizedTick = params => {
        const { x, y, payload } = params;

        const formattedTime = formatXAxisLabel(payload.value)

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="rgb(78,79,83)" fontSize='12px'>
                {
                    (props.timeframe.endsWith('d')) ? 
                    <>
                        <tspan textAnchor="middle" x="0">
                            {formattedTime.split(' ')[1]}
                        </tspan>
                        <tspan textAnchor="middle" x="0" dy="14">
                            {formattedTime.split(' ')[0].substring(0, formattedTime.split(' ')[0].length - 1)}
                        </tspan>
                    </> : 
                    <tspan textAnchor="middle" x="0">
                        {formattedTime}
                    </tspan>
                }
                </text>
            </g>
        );
    }

    return (
        <ResponsiveContainer style={{height:'100%'}}>
            {
                props.errorMessage !== '' ? 
                <div style={{margin:'auto', width:'fit-content', textAlign:'center'}}>
                    <WarningIcon style={{width:'7vw', marginTop:'80%', color:'red', height:'auto'}}/>
                    <br/>
                    <h4 style={{textAlign:'center'}}>{props.errorMessage}</h4>
                </div> 
                : 
                (props.data == null ? true : props.data.length === 0) ? 
                <Skeleton variant="rounded" animation='wave' width={210} height={59}/> 
                :
                <ComposedChart data={props.data} id='metrics-linechart' margin={{ top: 15, right: 20, left: 0, bottom: 25 }} animation={{duration: 250}}>
                    <CartesianGrid strokeDasharray="2 8" />

                    <XAxis dataKey="timestamp" interval={Math.ceil(props.data.length / 7)} grid={false} className="x-axis" 
                        tick={<CustomizedTick />}/>
                        
                    <YAxis grid={false} yAxisId="left" className="y-axis" tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}} domain={[0, 14]} tickCount={3}
                        label={{value: 'pH', position: 'outside', offset: 0, angle: -90, dx: -14, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}/>
                    
                    <Line yAxisId='left' type="monotone" dataKey='phvalue' stroke={`${colors[0]}`} strokeWidth={1.5} dot={false} />

                    {/* <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} allowDecimals={false} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}}
                        label={{value: `Events`,position: 'outside', offset: 0, angle: 90, dx: 30, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}/> */}

                    <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }} 
                        wrapperStyle={{ outline: "none" }}
                        labelFormatter={formatXAxisLabel}
                        formatter={formatReadings}
                    />

                    {/* {props.loggedUnits.map((unit, i) => {
                        if(unit.checked) return <Line yAxisId='left' type="monotone" dataKey={`${unit.name}.${props.plotSpecs.metricType === 'Power (kWh)' ? 'power_kwh' : 'current'}`} stroke={`${colors[i % colors.length]}`} strokeWidth={1.5} dot={false} />
                    })} */}
                </ComposedChart>
            }
        </ResponsiveContainer>
    );
}

export default TimeseriesGraph;
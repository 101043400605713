import React, { useEffect, useState} from "react";
import { Button, Modal  } from "react-bootstrap";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from "@mui/material/InputLabel";
import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import countries from '../../../example_data/dropdowns/clientModal/countries';
import provinces from '../../../example_data/dropdowns/clientModal/provinces';
import states from '../../../example_data/dropdowns/clientModal/states';
import gas_types from '../../../example_data/dropdowns/clientModal/gasTypes';

const GHGModal = props => {
    const floatRegex = /^(-?\d*\.?\d*)?$/;

    const [regionForm, setRegionForm] = useState({
        region: '',
        regionError: '',
        country: 'Canada',
        countryError: ''
    });

    var years = new Array(20);
    let nextYear = new Date().getFullYear() + 1;
    
    let index = 0;
    while(index < 20){
        years[index++] = nextYear--;    
    }

    const [factorForm, setFactorForm] = useState({
        water_kg_co2_m3: '',
        waterError: '',
        elec_kg_co2_kwh: '',
        elecError: '',
        gas_kg_co2_btu: '',
        gasError: '',
        year: new Date().getFullYear()
    });

    const validateForm = () => {
        let isValid = true;

        if(props.modalState === 'region'){
            let tempForm = {...regionForm};

            if(tempForm.region.trim() === ''){
                isValid = false;
                tempForm.regionError = 'Required';
            }else{
                tempForm.regionError = '';
            }

            if(tempForm.country.trim() === ''){
                isValid = false;
                tempForm.countryError = 'Required';
            }else{
                tempForm.countryError = '';
            }

            setRegionForm(tempForm);
            return isValid;

        }else if(props.modalState === 'factor' || props.modalState === 'edit-factor'){
            let tempForm = {};

            if(props.modalState === 'factor'){
                tempForm = {...factorForm};
            }else{
                tempForm = {...props.factorToEdit};
            }

            if(tempForm.water_kg_co2_m3.toString().trim() === ''){
                tempForm.waterError = 'Required';
                isValid = false;
            }else if(parseFloat(tempForm.water_kg_co2_m3) <= 0){
                tempForm.waterError = 'Value must be greater than 0';
                isValid = false;
            }else{
                tempForm.waterError = '';
            }

            if(tempForm.elec_kg_co2_kwh.toString().trim() === ''){
                tempForm.elecError = 'Required';
                isValid = false;
            }else if(parseFloat(tempForm.elec_kg_co2_kwh) <= 0){
                tempForm.elecError = 'Value must be greater than 0';
                isValid = false;
            }else{
                tempForm.elecError = '';
            }

            if(tempForm.gas_kg_co2_btu.toString().trim() === ''){
                tempForm.gasError = 'Required';
                isValid = false;
            }else if(parseFloat(tempForm.gas_kg_co2_btu) <= 0){
                tempForm.gasError = 'Value must be greater than 0';
                isValid = false;
            }else{
                tempForm.gasError = '';
            }

            if(props.modalState === 'factor'){
                setFactorForm(tempForm);
            }else{
                props.setFactorToEdit(tempForm);
            }

            return isValid;
        }
    }

    const generateModalTitle = () => {
        if(props.modalState === 'region'){
            return 'Add new region';
        }else if(props.modalState === 'factor'){
            return 'Add new factor set';
        }else if(props.modalState === 'edit-factor'){
            return 'Edit factor'
        }else if(props.modalState === 'delete-factor'){
            return 'Delete factor?'
        }

        return '';
    }

    return (
        <Modal show={props.modalState !== ''} onHide={() => {
            props.setServerError('');
            
            if(props.modalState === 'edit-factor'){
                props.setFactorToEdit(null);
            }else if(props.modalState === 'delete-factor'){
                props.setFactorToDelete(null);
            }else{
                props.setModalState('');
            }
        }} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{generateModalTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.serverError !== '' && <p style={{color:'red', textAlign:'center'}}>{props.serverError}</p>}
                {
                    props.modalState === 'region' &&
                    <>
                        {
                            (regionForm.country === 'Canada' || regionForm.country === 'USA') ? 
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{regionForm.country === 'Canada' ? 'Province' : 'State'}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={regionForm.region}
                                    label={regionForm.country === 'Canada' ? 'Province' : 'State'}
                                    onChange={e => {
                                        setRegionForm(prev => ({
                                            ...prev,
                                            ['region']: e.target.value
                                        }))
                                    }}
                                    error={regionForm.regionError !== ''}
                                >
                                    {
                                        regionForm.country === 'Canada' ? 
                                        provinces.map((province, i) => (
                                            <MenuItem value={province} key={i}>{province}</MenuItem>
                                        ))
                                        :
                                        states.map((state, i) => (
                                            <MenuItem value={state} key={i}>{state}</MenuItem>
                                        ))
                                    }
                                </Select>
                                <small style={{color:'red'}}>{regionForm.regionError}</small>
                            </FormControl>
                            :
                            <TextField
                                variant="outlined"
                                fullWidth
                                label='Province/state/territory'
                                value={regionForm.region}
                                onChange={e => {
                                    setRegionForm(prev => ({
                                        ...prev,
                                        ['region']: e.target.value
                                    }))
                                }}
                                error={regionForm.regionError !== ''}
                                helperText={regionForm.regionError}
                            />
                        }

                        <br/>
                        <br/>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={regionForm.country}
                                label="Country"
                                onChange={e => {
                                    setRegionForm(prev => ({
                                        ...prev,
                                        ['country']: e.target.value,
                                        region: ''
                                    }))
                                }}
                                error={regionForm.countryError !== ''}
                            >
                                {
                                    countries.map((country, i) => (
                                        <MenuItem value={country} key={i}>{country}</MenuItem>
                                    ))
                                }
                            </Select>
                            <small style={{color:'red'}}>{regionForm.countryError}</small>
                        </FormControl>
                    </>
                }
                {
                    (props.modalState === 'factor' || props.modalState === 'edit-factor')  && 
                    <>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-label">Water emissions factor</InputLabel>
                            <OutlinedInput
                                label='Water emissions factor'
                                endAdornment={<InputAdornment position="end">kg CO<sub>2</sub>e/m<sup>3</sup></InputAdornment>}
                                value={props.modalState === 'edit-factor' ? props.factorToEdit?.water_kg_co2_m3 : factorForm.water_kg_co2_m3}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;

                                    if(props.modalState === 'edit-factor'){
                                        props.setFactorToEdit(prev => ({
                                            ...prev,
                                            ['water_kg_co2_m3']: e.target.value
                                        }));
                                    }else{
                                        setFactorForm(prev => ({
                                            ...prev,
                                            ['water_kg_co2_m3']: e.target.value
                                        }));
                                    }
                                }}
                                error={props.modalState === 'edit-factor' ? props.factorToEdit?.waterError !== '' : factorForm.waterError !== ''}
                            />
                            <small style={{color:'red'}}>{props.modalState === 'edit-factor' ? props.factorToEdit?.waterError : factorForm.waterError}</small>
                        </FormControl>

                        <br/>
                        <br/>

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-label">Electricity emissions factor</InputLabel>
                            <OutlinedInput
                                label='Electricity emissions factor'
                                endAdornment={<InputAdornment position="end">kg CO<sub>2</sub>e/kWh</InputAdornment>}
                                value={props.modalState === 'edit-factor' ? props.factorToEdit?.elec_kg_co2_kwh : factorForm.elec_kg_co2_kwh}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;

                                    if(props.modalState === 'edit-factor'){
                                        props.setFactorToEdit(prev => ({
                                            ...prev,
                                            ['elec_kg_co2_kwh']: e.target.value
                                        }));
                                    }else{
                                        setFactorForm(prev => ({
                                            ...prev,
                                            ['elec_kg_co2_kwh']: e.target.value
                                        }));
                                    }
                                }}
                                error={props.modalState === 'edit-factor' ? props.factorToEdit?.elecError !== '' : factorForm.elecError !== ''}
                            />
                            <small style={{color:'red'}}>{props.modalState === 'edit-factor' ? props.factorToEdit?.elecError : factorForm.elecError}</small>
                        </FormControl>

                        <br/>
                        <br/>

                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-label">Gas emissions factor</InputLabel>
                            <OutlinedInput
                                label='Gas emissions factor'
                                endAdornment={<InputAdornment position="end">kg CO<sub>2</sub>e/BTU</InputAdornment>}
                                value={props.modalState === 'edit-factor' ? props.factorToEdit?.gas_kg_co2_btu : factorForm.gas_kg_co2_btu}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;

                                    if(props.modalState === 'edit-factor'){
                                        props.setFactorToEdit(prev => ({
                                            ...prev,
                                            ['gas_kg_co2_btu']: e.target.value
                                        }));
                                    }else{
                                        setFactorForm(prev => ({
                                            ...prev,
                                            ['gas_kg_co2_btu']: e.target.value
                                        }));
                                    }
                                }}
                                error={props.modalState === 'edit-factor' ? props.factorToEdit?.gasError !== '' : factorForm.gasError !== ''}
                            />
                            <small style={{color:'red'}}>{props.modalState === 'edit-factor' ? props.factorToEdit?.gasError : factorForm.gasError}</small>
                        </FormControl>
                        
                        <br/>
                        <br/>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                disabled={props.modalState === 'edit-factor'}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={factorForm.year}
                                label='Year'
                                onChange={e => {
                                    setFactorForm(prev => ({
                                        ...prev,
                                        ['year']: e.target.value
                                    }))
                                }}
                            >
                                {
                                    years.map((year, i) => (
                                        <MenuItem value={year} key={i}>{year}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
                {
                    props.modalState === 'delete-factor' &&
                    <p>Are you sure you want to permanently delete the {props.factorToDelete?.year} GHG factors for {props.factorToDelete?.territory}?</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{backgroundColor:`${props.modalState === 'delete-factor' ? '#DC4C64' : 'rgb(8,114,182)'}`, outline:'none', border:'none'}}
                    onClick={() => {
                        if(props.modalState === 'delete-factor'){
                            props.deleteFactor();
                            return;
                        }
                        
                        if(!validateForm()) return;

                        if(props.modalState === 'region') props.submitForm(regionForm);
                        else if(props.modalState === 'factor') props.submitForm(factorForm);
                        else if(props.modalState === 'edit-factor') props.submitForm(props.factorToEdit);
                    }}
                >
                    {props.modalState === 'delete-factor' ? 'Delete' : 'Submit'}
                </Button>
            </Modal.Footer>
        </Modal>
      );
  }

export default GHGModal;
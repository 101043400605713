import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import clientSectors from '../../../../example_data/dropdowns/clientModal/clientSectors';
import countries from '../../../../example_data/dropdowns/clientModal/countries';
import provinces from '../../../../example_data/dropdowns/clientModal/provinces';
import states from '../../../../example_data/dropdowns/clientModal/states';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';

const OrgModal = props => {
    const axiosProtect = useAxiosProtect();

    const [name, setName] = useState('');
    const [sector, setSector] = useState('');
    const [employees, setEmployees] = useState('');
    // location
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('')
    const [country, setCountry] = useState('')
    const [provinceList, setProvinceList] = useState(provinces);

    //errors
    const [nameErr, setNameErr] = useState('');
    const [sectorErr, setSectorErr] = useState('');
    const [employeeErr, setEmployeeErr] = useState('');
    const [cityErr, setCityErr] = useState('');
    const [provinceErr, setProvinceErr] = useState('');
    const [countryErr, setCountryErr] = useState('');
    
    // delete UI states
    const [delName, setDelName] = useState('');
    const [delNameErr, setDelNameErr] = useState('');

    const closeModal = () => {
        setName('')
        setSector('')
        setEmployees('')
        setCity('')
        setProvince('')
        setCountry('')
        setDelName('')

        setNameErr('')
        setSectorErr('')
        setEmployeeErr('')
        setCityErr('')
        setProvinceErr('')
        setCountryErr('')
        setDelNameErr('')

        props.toggleModalState(undefined);
    }

    const changeSector = e =>{
        setSector(e.target.value);
    }

    const changeProvince = e => {
        setProvince(e.target.value)
    }

    const changeCountry = e => {
        if(e.target.value === 'Canada'){
            setProvinceList(provinces)

        }else if (e.target.value === 'USA'){
            setProvinceList(states)

        }else{
            setProvinceList([])
        }

        setProvince('');
        setCountry(e.target.value);
    }

    const isValidSector = () => {
        if(sector === ''){
            setSectorErr('Required');
            return false;
        }

        for(let i = 0;i<clientSectors.length;++i){
            if(sector === clientSectors[i]){
                setSectorErr('');
                return true;
            }
        }

        setSectorErr('Invalid');
        return false;
    }

    const isValidLocation = () => {
        if(country === ''){
            setCountryErr('Required');
            return false;
        }

        if(country === 'Canada' || country === 'USA'){
            setCountryErr('');

            if(province === ''){
                setProvinceErr('Required');
                return false;
            }

            if(country === 'Canada'){
                for(let i = 0;i<provinces.length;++i){
                    if(province === provinces[i]){
                        setProvinceErr('');
                        return true;
                    }
                }
            }else{
                for(let i = 0;i<states.length;++i){
                    if(province === states[i]){
                        setProvinceErr('');
                        return true;
                    }
                }
            }
            
            setProvinceErr('Invalid');
            return false;
        }else{
            let invalidCountry = true;

            for(let i = 0;i<countries.length && invalidCountry;++i){
                if(country === countries[i]){
                    setCountryErr('');
                    invalidCountry = false;
                }
            }

            if(invalidCountry){
                setCountryErr('Invalid');
                return false;
            }

            if(province !== ''){
                setProvinceErr('Invalid');
                return false
            }

            setProvinceErr('');
            return true;
        }
    }

    const isValidForm = isDelete => {
        if(isDelete){
            if(delName === ''){
                setDelNameErr('Required');
                return false;
            }else if(props.delClient.name === delName){
                setDelNameErr('');
                return true;
            }
            
            setDelNameErr('Names do not match');
            return false;
        }

        let isValid = true;

        if(name === ''){
            setNameErr('Required');
            isValid = false;
        }else setNameErr('');

        if(employees === ''){
            setEmployeeErr('Required');
            isValid = false;
        }else if(isNaN(parseInt(employees))){
            setEmployeeErr('Invalid');
            isValid = false;
        }else setEmployeeErr('');

        if(city === ''){
            setCityErr('Required');
            isValid = false;
        }else setCityErr('');

        if(!isValidSector()) isValid = false;
        if(!isValidLocation()) isValid = false;

        return isValid;
    }

    const deleteClient = async () => {
        const controller = new AbortController();

        try{
            const response = await axiosProtect.delete(`/auth/org/delete?id=${props.delClient.id}`, {
                signal: controller.signal,
            });

            if(response.status === 200){
                props.removeClientCard(props.delClient.name)
            }

            closeModal();
        }catch(err){
            setDelNameErr(err?.response?.data)
        }
    }

    const createClient = async () => {
        const controller = new AbortController();

        try{
            const response = await axiosProtect.post('/auth/org/add', {
                signal: controller.signal,
                name: name,
                industry: sector,
                employees: employees,
                hq_city: city,
                hq_state: province,
                hq_country: country,
                es_manager: props.selectedESUser
            });

            if(response.status === 200){
                props.addClientCard(response.data)
            }

            closeModal();
        }catch(err){
            setNameErr(err?.response?.data)
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        console.log('hh')
        let isDelete = props.orgModalState === 'delete';
                        
        if(isValidForm(isDelete)){
            if(isDelete) deleteClient();
            else createClient();
        }
    }

    return (
        <Modal
            show={props.orgModalState !== undefined}
            onHide={() => closeModal()}
            backdrop="static"
            keyboard={false}
            centered
        >
        <Modal.Header closeButton>
            <h4>{`${props.orgModalState === 'add' ? 'Create' : props.orgModalState === 'delete' ? 'Delete' : ''} Client`}</h4>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
            <Modal.Body>
                
            {props.orgModalState === 'add' ? 
                <div>
                    <p>General Info</p>
                    <TextField 
                        id="outlined-basic" 
                        label="Client name" 
                        variant="outlined"
                        style={{width:'100%', marginBottom:'1.25vh'}}
                        error={nameErr !== ''}
                        helperText={nameErr}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                        <Select
                            id="es-client-add-sector"
                            value={sector}
                            label="Industry"
                            onChange={changeSector}
                            style={{width:'100%', marginBottom:'1.25vh'}}
                            error={sectorErr !== ''}
                            helperText={sectorErr}
                        >
                            {clientSectors.map((_, i) => (
                                <MenuItem value={clientSectors[i]} key={i}>{clientSectors[i]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField 
                        id="outlined-basic" 
                        label="Employees" 
                        variant="outlined"
                        style={{width:'100%', marginBottom:'1.25vh'}}
                        value={employees}
                        onChange={e => {
                            if(e.target.value === '') setEmployees('')
                            let input = parseInt(e.target.value)
                            setEmployees(isNaN(input) ? '' : input)
                        }}
                        error={employeeErr !== ''}
                        helperText={employeeErr}
                    />

                    <p>HQ Info (HQ location - not site-specific)</p>
                    
                    <TextField 
                        id="outlined-basic" 
                        label="City" 
                        variant="outlined"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        style={{width:'100%', marginBottom:'1.25vh'}}
                        error={cityErr !== ''}
                        helperText={cityErr}
                    />

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                        <Select
                            id="es-client-add-sector"
                            value={country}
                            label="Country"
                            onChange={changeCountry}
                            style={{width:'100%', marginBottom:'1.25vh'}}
                            error={countryErr !== ''}
                            helperText={countryErr}
                        >
                            {countries.map((_, i) => (
                                <MenuItem value={countries[i]} key={i}>{countries[i]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Province/state</InputLabel>
                        <Select
                            id="es-client-add-sector"
                            value={province}
                            label="Province/state"
                            onChange={changeProvince}
                            style={{width:'100%', marginBottom:'1.25vh'}}
                            error={provinceErr !== ''}
                            helperText={provinceErr}
                        >
                            {provinceList.map((_, i) => (
                                <MenuItem value={provinceList[i]} key={i}>{provinceList[i]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <p>Management</p>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">E-S Manager</InputLabel>
                        <Select
                            id="es-managers"
                            value={props.selectedESUser}
                            label="E-S Manager"
                            onChange={e => {
                                props.setSelectedESUser(e.target.value);
                            }}
                            style={{width:'100%', marginBottom:'1.25vh'}}
                        >
                            {props.esUsers.map((user, i) => (
                                <MenuItem value={user._id} key={i}>{`${user.first_name} ${user.last_name}`}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    </div> 
                    : 
                    props.orgModalState === 'delete' ?
                    <div>
                        <p>Type "<span style={{fontStyle:'italic'}}>{props.delClient.name}</span>" to confirm</p>

                        <TextField 
                            id="outlined-basic" 
                            variant="outlined"
                            style={{width:'100%', marginBottom:'1.25vh'}}
                            error={delNameErr !== ''}
                            helperText={delNameErr}
                            value={delName}
                            onChange={e => setDelName(e.target.value)}
                        />
                    </div> 
                    : 
                    <div></div>
                }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={props.orgModalState === 'add' ? 'primary' : 'danger'} 
                        style={{backgroundColor:`${props.orgModalState === 'add' ? 'rgb(8,114,182)' : 'rgb(213,43,0)'}`}}
                        type='submit'>{
                        props.orgModalState === 'add' ? 'Create' : props.orgModalState === 'delete' ? 'Delete' : ''}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default OrgModal;
import React, { useEffect, useRef, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import CommentIcon from '@mui/icons-material/Comment';

const DailyLog = props => {
    const [hasLogs, setHasLogs] = useState(true);

    useEffect(() => {
        let logsPresent = false;

        for(let i = 0;i<props.logs.length;++i){
            if(props.logs[i].logs.length > 0){
                logsPresent = true;
                break;
            }
        }

        setHasLogs(logsPresent);
    }, [props.logs]);

    const formatDate = (unixTime, includeTime) => {
        const date = new Date(unixTime * 1000);

        if(includeTime){
            return new Intl.DateTimeFormat("en-GB", {
                hour: '2-digit',
                minute: '2-digit',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                hour12: false
            }).format(date);
        }else{
            return new Intl.DateTimeFormat("en-GB", {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }).format(date);
        }        
    }

    return (
        <>
        {
            hasLogs ?
            <>
            {
                props.logs.map((log, i) => (
                    <>
                    {
                        log.logs.length === 0 ? 
                        <></>
                        :
                        <Accordion expanded={log.expanded} onChange={() => props.updateLogState(i)} style={{backgroundColor:'rgba(0,0,0,0)', width:'95vw'}}>
                        <AccordionSummary
                            style={{backgroundColor:`rgba(255,255,255,${log.logs.length > 0 ? 1 : 0})`}}
                            expandIcon={<ExpandMoreIcon style={{backgroundColor: log.logs.length > 0 ? 'rgb(8,114,182)' : 'white', borderRadius:'50%', color: log.logs.length > 0 ? 'white' : 'rgb(78,79,83)' }}/>}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{ width: '50%', flexShrink: 0 }} variant='h4' fontWeight={log.logs.length > 0 ? 'bold' : 'normal'} fontSize={'1.25rem'}>{formatDate(log.day, false)} ({log.logs.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ width:'fit-content'}}>
                                {
                                    log.logs.length === 0 ? 
                                    <p>No logs</p> :
                                    <VerticalTimeline layout='1-column-left'>
                                    {
                                        log.logs.map((logItem, j) => (
                                            <VerticalTimelineElement
                                                className="vertical-timeline-element--work"
                                                date={`${formatDate(parseInt(logItem.activity_time), true)}`}
                                                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                                icon={<CommentIcon />}
                                                position='right'
                                                contentStyle={{padding:'4% 6% 0% 6%'}}
                                            >
                                                <h3 className="vertical-timeline-element-title" style={{fontSize:'1.2rem'}}>{logItem.title}</h3>
                                                <h4 className="vertical-timeline-element-subtitle" style={{fontSize:'0.95rem'}}>{logItem.author}</h4>
                                                <p style={{marginBottom:'1%'}}>
                                                    {logItem.message}
                                                </p>
                                                {
                                                    props.uid === logItem.uid &&
                                                    <>
                                                    <p style={{margin:'0', fontSize:'0.75rem', color:'#d9534f', cursor:'pointer', textDecoration:'underline'}} onClick={() => {
                                                        props.deleteLog(logItem.id, i, j)
                                                    }}>Delete</p>
                                                    </>
                                                }
                                            </VerticalTimelineElement>
                                        ))
                                    }
                                    </VerticalTimeline>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    }
                    </>
                ))
            }
            </>
            :
            <div style={{width:'95vw', textAlign:'center'}}>
                <br/>
                <h4>No logs</h4>
            </div>
        }
        </>
    );
}

export default DailyLog;
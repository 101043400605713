import React from 'react';
import CountUp from 'react-countup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import './styling/CardStyle.css';
import { Skeleton } from '@mui/material';

const SummaryCard = props => {
    const now = Math.round( 100 * props.cost / props.totalCost);

    const watEmissions = Math.round( 100 * props.waterGHG / props.value);
    const elecEmissions = Math.round( 100 * props.elecGHG / props.value);
    const gasEmissions = Math.round( 100 * props.gasGHG / props.value);

    return(
        <div className='summary-card'>
            <div>
                <h4 className='card-name'>{props.title}</h4>
                {(props.value && props.windowName !== undefined) && <ReadMoreIcon onClick={() => {
                    window.name = props.windowName;
                    window.location.href = `/energy-metrics/${window.location.href.split('/').slice(-1)}`;
                }} className='card-toggle'/>}
            </div>
            
            {props.value ? 
                <span className='metric-span'><CountUp separator=',' className='metric-count' end={props.value} duration={1}/> {props.units}</span> :
                (props.present ? <Skeleton style={{height:'5vh'}}/> : <p>Not in scope</p>)
            }

            {props.title !== 'Emissions' ? (
                props.value ? 
                <>
                    <ProgressBar className='metrics-bar' variant='custom' now={now} label={`${now < 5 ? '' : `${now}%`}`} />
                    <p className='card-info-lg'>{now}% of utility costs in last 24h</p>
                    <small className='card-info-sm'>${props.cost} spent</small>
                </> : 
                <>
                    {props.present && <><Skeleton variant='rounded' style={{height:'5vh'}}/>
                    <Skeleton variant='text' style={{width:'50%'}}/></>}
                </>
            ) : (
                props.value ?
                <>
                    <ProgressBar>
                        <ProgressBar style={{backgroundColor:'rgb(0,177,168)'}} now={gasEmissions} key={1} label={`${gasEmissions < 5 ? '' : `${Math.round(100 * gasEmissions/(watEmissions + elecEmissions + gasEmissions))}%`}`}/>
                        <ProgressBar style={{backgroundColor:'rgb(213,43,0)'}} now={elecEmissions} key={2} label={`${elecEmissions < 5 ? '' : `${Math.round(100 * elecEmissions/(watEmissions + elecEmissions + gasEmissions))}%`}`}/>
                        <ProgressBar style={{backgroundColor:'rgb(8,114,182)'}} now={watEmissions} key={3} label={`${watEmissions < 5 ? '' : `${Math.round(100 * watEmissions/(watEmissions + elecEmissions + gasEmissions))}%`}`}/>
                    </ProgressBar>

                    <div className='ghg-legend'>
                        <div className='ghg-param'>
                            <div style={{backgroundColor:'rgb(0,177,168)'}} className='param-color'></div>
                            <p className='param-title'>Gas</p>
                        </div>

                        <div className='ghg-param'>
                            <div style={{backgroundColor:'rgb(213,43,0)'}} className='param-color'></div>
                            <p className='param-title'>Electricity</p>
                        </div>

                        <div className='ghg-param'>
                            <div style={{backgroundColor:'rgb(8,114,182)'}} className='param-color'></div>
                            <p className='param-title'>Water</p>
                        </div>
                        {props.title === 'Emissions' && <small id='emissions-cutsheet-text' onClick={props.openModal}>Current emission factors</small>}
                    </div>
                </> :
                <>
                    <Skeleton variant='rounded' style={{height:'5vh'}}/>
                    <Skeleton variant='text' style={{width:'50%'}}/>
                </>
            )}
        </div>
    );
}

export default SummaryCard;